export const DEFAULT_ENABLEDSERVICEBYCOMPANY = ['91', '99', '96', '95', '13', '94', '03', '93', '18', '90'];
export const DEFAULT_ENABLEDTYPECHAT = ['00', '31', '93', '99', 'C1', 'C2'];
export const ISINAPPWEBVIEWBROWSER = ['Chrome WebView', 'WebKit'];
export const ISINWEBVIEWBROWSERLIST = ['Facebook', 'Chrome WebView', 'Line', 'WebKit'];
export const AUTH_PROVIDER_BYPASS_PATH = [
    '/line/oauth2callback',
    '/user/line',
    '/line/redirect',
    '/fb',
    '/user/sso',
    '/partner20/redirect',
];
export const NEW_CONSENT_WITH_THEME_LIST = ['default', 'fastcare', 'pharmcareads', 'doctorhub', 'central', 'yaphrom'];
export const LANGUAGE_LIST = ['th', 'en'];
export const TASK_REJECT_TASK_STATUS_CODE_LIST = [2, 4, 6, 12, 14, 16, 22, 24, 26];
export const ALLOW_DELIVERY_COUNTRY_CODE = ['TH'];

export const API_CALL_TYPES = {
    default_oceanopd: 'OCEAN_OPD_API',
    default_sunday: 'SUNDAY_OPD_API',
    workplaze: 'DATAON_OPD_API',
    19: 'OCEAN_OPD_API',
    13: 'DATAON_OPD_API',
    '01': 'SUNDAY_OPD_API',
    default: 'INTERNAL_DB',
};
export const INSURANCE_COMPANY = {
    ocean: {
        imageUrl: '/images/claim/logo-ocean.jpg',
        companyCode: '19',
    },
    sunday: {
        imageUrl: '/images/claim/logo-sunday.png',
        companyCode: '01',
    },
    pharmcare: {
        imageUrl: '/images/logo/pharmcare-logo.webp',
        companyCode: '03',
    },
};

export const DEFAULT_THEME = ['default', 'default_theme', 'default-theme'];

export const THEME_CONFIG_LIST = [
    'default',
    'central',
    'central20',
    'central30',
    'doctorhub',
    'default_humatrix',
    'default_pharmcareads',
    'default_oceanopd',
    'default_sunday',
    'partner_pharmacy',
    'default-theme',
    'ptg',
    'cpa',
    'yaphrom',
    'betterperiod',
    'rabbit',
    'benix',
    'fastcare',
    'humatrix',
    'workplaze',
    'pandf',
    'morningmind',
    'livewell',
];

export const CONTACT_NUMBER_WITH_THEME = {
    19: '1503',
    '01': '02-026-3355',
    default_oceanopd: '1503',
    default_sunday: '02-026-3355',
    rabbit: '1438',
    default: '',
};

export const OPEN_SERVICE_DATE_TIME_WITH_THEME = {
    defaultService: [
        { day: 'Monday', open: '00:00', close: '23:59' },
        { day: 'Tuesday', open: '00:00', close: '23:59' },
        { day: 'Wednesday', open: '00:00', close: '23:59' },
        { day: 'Thursday', open: '00:00', close: '23:59' },
        { day: 'Friday', open: '00:00', close: '23:59' },
        { day: 'Saturday', open: '00:00', close: '23:59' },
        { day: 'Sunday', open: '00:00', close: '23:59' },
    ],
    telepharmacy: [
        { day: 'Monday', open: '00:00', close: '23:59' },
        { day: 'Tuesday', open: '00:00', close: '23:59' },
        { day: 'Wednesday', open: '00:00', close: '23:59' },
        { day: 'Thursday', open: '00:00', close: '23:59' },
        { day: 'Friday', open: '00:00', close: '23:59' },
        { day: 'Saturday', open: '00:00', close: '23:59' },
        { day: 'Sunday', open: '00:00', close: '23:59' },
    ],
    tlm: [
        { day: 'Monday', open: '09:00', close: '20:00' },
        { day: 'Tuesday', open: '09:00', close: '20:00' },
        { day: 'Wednesday', open: '09:00', close: '20:00' },
        { day: 'Thursday', open: '09:00', close: '20:00' },
        { day: 'Friday', open: '09:00', close: '20:00' },
        { day: 'Saturday', open: '09:00', close: '20:00' },
        { day: 'Sunday', open: '09:00', close: '20:00' },
    ],
    tlmNcd: [
        { day: 'Monday', open: '09:00', close: '20:00' },
        { day: 'Tuesday', open: '09:00', close: '20:00' },
        { day: 'Wednesday', open: '09:00', close: '20:00' },
        { day: 'Thursday', open: '09:00', close: '20:00' },
        { day: 'Friday', open: '09:00', close: '20:00' },
        { day: 'Saturday', open: '09:00', close: '20:00' },
        { day: 'Sunday', open: '09:00', close: '20:00' },
    ],
    telemed: [
        { day: 'Monday', open: '09:00', close: '20:00' },
        { day: 'Tuesday', open: '09:00', close: '20:00' },
        { day: 'Wednesday', open: '09:00', close: '20:00' },
        { day: 'Thursday', open: '09:00', close: '20:00' },
        { day: 'Friday', open: '09:00', close: '20:00' },
        { day: 'Saturday', open: '09:00', close: '20:00' },
        { day: 'Sunday', open: '09:00', close: '20:00' },
    ],
    physiotherapy: [
        { day: 'Monday', open: '10:00', close: '20:00' },
        { day: 'Tuesday', open: '10:00', close: '20:00' },
        { day: 'Wednesday', open: '10:00', close: '20:00' },
        { day: 'Thursday', open: '10:00', close: '20:00' },
        { day: 'Friday', open: '10:00', close: '20:00' },
        { day: 'Saturday', open: '10:00', close: '20:00' },
        { day: 'Sunday', open: '10:00', close: '20:00' },
    ],
    nutritionist: [
        { day: 'Monday', open: '10:00', close: '20:00' },
        { day: 'Tuesday', open: '10:00', close: '20:00' },
        { day: 'Wednesday', open: '10:00', close: '20:00' },
        { day: 'Thursday', open: '10:00', close: '20:00' },
        { day: 'Friday', open: '10:00', close: '20:00' },
        { day: 'Saturday', open: '10:00', close: '20:00' },
        { day: 'Sunday', open: '10:00', close: '20:00' },
    ],
    mth: [
        { day: 'Monday', open: '11:00', close: '20:00' },
        { day: 'Tuesday', open: '11:00', close: '20:00' },
        { day: 'Wednesday', open: '11:00', close: '20:00' },
        { day: 'Thursday', open: '11:00', close: '20:00' },
        { day: 'Friday', open: '11:00', close: '20:00' },
        { day: 'Saturday', open: '11:00', close: '20:00' },
        { day: 'Sunday', open: '11:00', close: '20:00' },
    ],
    fulfillment: [
        { day: 'Monday', open: '00:00', close: '23:59' },
        { day: 'Tuesday', open: '00:00', close: '23:59' },
        { day: 'Wednesday', open: '00:00', close: '23:59' },
        { day: 'Thursday', open: '00:00', close: '23:59' },
        { day: 'Friday', open: '00:00', close: '23:59' },
        { day: 'Saturday', open: '00:00', close: '23:59' },
        { day: 'Sunday', open: '00:00', close: '23:59' },
    ],
};

export const DEFAULT_ADDRESS_TYPE_LIST = [
    { addressTypeCode: '01', addressTypeDescription: 'address:label_option.home', addressTypeIconClass: 'address_type_home' },
    { addressTypeCode: '02', addressTypeDescription: 'address:label_option.office', addressTypeIconClass: 'address_type_Office' },
    { addressTypeCode: '03', addressTypeDescription: 'address:label_option.official', addressTypeIconClass: 'address_type_Official' },
];

export const VOUCHER_WITH_PATHNAME = [
    { path: '/ATK1', redirect: process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=ATK1' },
    { path: '/ATK2', redirect: process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=ATK2' },
    { path: '/covid', redirect: process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=covid' },
    { path: '/ocean250', redirect: process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=ocean250' },
    { path: '/ocean450', redirect: process.env.REACT_APP_LIFF_APPID_CLAIM_TLM + '?voucher=ocean450' },
];

export const PUBLIC_ROUTES_WITH_YAPHROM_FASTCARE = [
    '/line/incomplete-oa',
    '/line/oauth2callback',
    '/line/redirect',
    '/user/line',
    '/user/signup/line',
    '/store/nearby',
    '/store/detail',
    '/store/detail/favourite',
    '/service/telepharmacy',
    '/service/telemed',
    '/service/physiotherapy',
    '/service/nutritionist',
    '/service/atk',
    '/service/telepharmacy-consult',
    '/landing/telepharmacy',
    '/landing/telemed',
    '/landing/physiotherapy',
    '/landing/nutritionist',
    '/landing/atk',
    '/landing/telepharmacy-consult',
];

/**
 * @see latitude = 13.765065
 * @see longitude = 100.538285
 */
export const DEFAULT_LATLNG = {
    latitude: 13.765065,
    longitude: 100.538285,
};

/**
 *
 * @see SM = 576
 * @see XS = 576
 * @see MD = 768
 * @see LG = 992
 * @see XL = 1200
 * @see XXL = 1440
 * @see XXL = 1680
 *
 */
export const DEFAULT_BREAKPOINTS_SIZE = {
    SM: 576,
    XS: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1440,
    XXXL: 1680,
};
