import { POST } from 'api';
import React, { Component } from 'react';
import { MenuBox, Navigation } from 'components/common/navigation';
import { Panel } from 'components/common/styled-components-common';
import { HomeServiceAlive } from 'modules/page/home';
import Breadcrumbs from 'components/common/breadcrumbs';
import { Section } from 'components/layouts/page-layout';
import styled from '@emotion/styled';
import { breakpoint, numberjs } from 'utils';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { withTranslation } from 'react-i18next';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';

class HomeAlive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultCoverage: {},
        };
    }

    componentDidMount() {
        if (this.props.theme !== 'default') {
            this.removeRichmenu();
        } else {
            this.getCoverage();
        }
    }

    getCoverage = async () => {
        let companyCode = null;
        const data = {
            phoneNumber: this.props.profile.phoneNumber,
            userPidType: this.props.profile.userPidType,
            userPid: this.props.profile.userPid || null,
            dateOfBirth: this.props.profile.dateOfBirth,
        };
        if (this.props.profile?.userXPartner && this.props.profile?.userXPartner?.partnerCompanyCode) {
            companyCode = this.props.profile.userXPartner?.partnerCompanyCode;
        }
        try {
            const result = await POST('/claim/checkCoverage', { data, companyCode });
            this.setState({
                defaultCoverage: result.defaultCoverage || [],
            });
        } catch (error) {
            console.error('claim/checkCoverage --> error', error);
        }
    };
    removeRichmenu = async () => {
        //remove theme default_xxx -> default
        if (this.props.auth.isAuth) {
            try {
                await POST('/user/remove-richmenu');
                window.location.reload();
            } catch (error) {
                console.error('/user/remove-richmenu ~ error', error);
            }
        }
    };
    render() {
        const { t } = this.props;
        const { defaultCoverage } = this.state;
        return (
            <React.Fragment>
                <Breadcrumbs
                    onBack={() => this.props.navigate(-1)}
                    title={this.props.theme.includes('default') ? t('home:pharmcare_service') : t('home:service')}
                    icon={<IconConfigWithTheme pageName='home_alive' iconName='breadcrumbs' />}
                />
                <StyleExtendsSection>
                    <Panel className='profile-panel'>
                        <div className='profile-photo'>
                            <img src={this.props.profile?.userImgUrl} alt='profile photo' />
                        </div>
                        <div className='profile-detail'>
                            <div className='text-gray-1'>{this.props.profile.displayName}</div>
                            {defaultCoverage?.defaultCoverageFlag === 1 && (
                                <div>วงเงิน: {numberjs(defaultCoverage?.amount).format('double', 0, 2)} บาท</div>
                            )}
                        </div>
                        {defaultCoverage?.defaultCoverageFlag === 1 && (
                            <div className='coverage-logo'>
                                <img src={defaultCoverage?.imageUrl} alt='coverage logo' />
                            </div>
                        )}
                    </Panel>
                    <div className='service-title'>{t('home:our_services_choose')}</div>
                    <HomeServiceAlive />
                </StyleExtendsSection>
                <WorkplazeThemeHideElement>
                    <Navigation>
                        <MenuBox
                            to='/home'
                            tag='/home'
                            title={t('navigation:home')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='home' />}
                        />
                        {this.props.theme != 'default_oceanopd' && (
                            <MenuBox
                                to='/phr/existing'
                                tag='/phr'
                                title={t('navigation:health_record')}
                                icon={<IconConfigWithTheme pageName='navigation' iconName='phr' />}
                            />
                        )}
                        <MenuBox
                            to='/service'
                            tag='/service'
                            title={t('navigation:acitivites')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='service' />}
                        />
                    </Navigation>
                </WorkplazeThemeHideElement>
            </React.Fragment>
        );
    }
}

const StyleExtendsSection = styled(Section)`
    label: user-plugin;

    .profile-panel {
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100% - (24px * 2));
        max-width: 600px;
        margin: 3rem auto;

        .profile-photo {
            border-radius: 50%;
            background-color: var(--text-normal-7);
            width: 100px;
            min-width: 100px;
            height: 100px;
            min-height: 100px;
            overflow: hidden;
            margin-right: 0.5rem;
            > img {
                object-fit: cover;
            }
        }
        .coverage-logo {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 80px;
            margin-left: auto;
        }
    }

    .service-title {
        color: var(--primary-color);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
    }

    ${breakpoint('XS')} {
        /* padding: 18px; */
        /* margin-bottom: calc(1rem + 30px); */
        padding-bottom: 100px;

        .profile-panel {
            margin-top: 0;

            .profile-photo {
                width: 80px;
                min-width: 80px;
                height: 80px;
                min-height: 80px;
            }
        }
    }
`;
export default withTranslation(['home', 'navigation'])(HomeAlive);
