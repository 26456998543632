const isNumberClass = (d) => d instanceof NumberClass; // eslint-disable-line no-use-before-define

/**
 * @version 1.0.4 fix array has 1 length and array typeof "Number(String)", return "Number(String)", but should return typeof "Number"
 * @version 1.0.3 fix parseNumber typeof value equa array but empty array and return NaN
 * @version 1.0.2 add function "del", "div", "mul"
 * @version 1.0.1
 *
 * @param {string | number | string[] | number[]} value
 * @param {any[] | undefined} c
 * @returns {{
 * value: number,
 * add: numberjs,
 * del: numberjs,
 * div: numberjs,
 * mul: numberjs,
 * reduce: numberjs,
 * format: (type: 'double' | 'integer' | 'int', minDigits: number, maxDigits: number)=>string
 * }}
 */

const numberjs = function (value, c = {}) {
    if (isNumberClass(value)) {
        return value.clone();
    }
    const cfg = typeof c === 'object' ? c : {};
    cfg.value = value;
    return new NumberClass(cfg);
};

/**
 *
 * @param {number | number[]} value
 * @param {object} instance
 * @returns {{value: number}}
 */
const wrapper = (value, instance) =>
    numberjs(value, {
        value: instance.value,
    });

const Utils = {}; // for plugin use
Utils.w = wrapper;

const isNegative = (num) => Math.sign(num) === -1;

/**
 *
 * @param {number | number[]} value
 * @returns {number}
 */

const parseNumber = (value) => {
    if (!value && !isNegative(value)) return 0;

    if (Array.isArray(value)) {
        if (value.length <= 0) return 0;

        if (value.length === 1) return parseFloat(value);

        return value.reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)) || 0;
    }

    // **** if "Infinity", isFinite() will return false
    if (isNaN(value) || !isFinite(value)) return 0;

    return parseFloat(value);
};

class NumberClass {
    constructor(cfg) {
        this.parse(cfg);
    }

    parse(cfg) {
        this.$number = cfg;
        this.init();
    }

    init() {
        const { $number } = this;
        this.value = parseNumber($number.value);
    }

    reduce(value) {
        this.value += parseNumber(value);

        return this.clone();
    }

    add(value) {
        this.value += parseNumber(value);

        return this.clone();
    }

    // minus
    del(value) {
        this.value = this.value - parseNumber(value);

        return this.clone();
    }

    // divide
    div(value) {
        this.value = this.value / parseNumber(value);

        return this.clone();
    }

    // multiply
    mul(value) {
        this.value = this.value * parseNumber(value);

        return this.clone();
    }

    /**
     *
     * @param {'int' | 'integer' | 'double'} type
     * @param {number} min
     * @param {number} max
     * @returns {string}
     */
    format(type, min = 0, max = 0) {
        const _type = type?.toLowerCase();

        if (_type === 'int' || _type === 'integer') return parseInt(this.value).toLocaleString();
        if (_type === 'double')
            return parseFloat(this.value).toLocaleString('th-TH', {
                minimumFractionDigits: min,
                maximumFractionDigits: max || min,
            });

        return '0';
    }

    clone() {
        return Utils.w(this.value, this);
    }
}

export default numberjs;
