import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { Global } from '@emotion/react';
import RoutesManagement from './routes';
import { AuthProvider } from 'context/AuthProvider';
import { PageProvider } from 'context/PageProvider';
import GlobalStyle from 'styles/global';
import { getCookie } from 'cookies-next';
import { hotjar } from 'react-hotjar';
import ThemeWithPartnerProvider from 'context/ThemeWithPartnerProvider';
import DeveloperTools from 'components/common/deverloper-tools';
import EXPPopupJs from 'components/common/exp-popupjs';
import EXPLiftUp from 'components/common/exp-lift-up';

let globalPopupRef = null;
let globalLiftUpRef = null;

// Initialize Firebase
// const firebaseApp = firebase.apps.length ? firebase.app() : firebase.initializeApp(FIREBASE_CONFIG[process.env.REACT_APP_PROFILE])

// Initialize Sentry
Sentry.init({
    enabled: process.env.REACT_APP_ENABLED_SENTRY === 'true',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new CaptureConsole({
            // array of methods that should be captured
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ['error', 'debug'],
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [process.env.REACT_APP_WEB_URL, process.env.REACT_APP_API_URL],
    // allowUrls: [process.env.REACT_APP_WEB_URL, process.env.REACT_APP_API_URL],
    denyUrls: [/http:\/\/localhost/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

function InitPopup() {
    const forceCode = () => {
        EXPPopupJs.close();
    };

    useEffect(() => {
        const registerModal = () => {
            console.log('%c>> registerModal', 'background: #f0f; color: #fff');
            EXPPopupJs.register(globalPopupRef);
        };

        registerModal();
        window.addEventListener('onchangepage', forceCode);

        return () => window.removeEventListener('onchangepage', forceCode);
    });
    return <EXPPopupJs.Init ref={(ref) => (globalPopupRef = ref)} />;
}
function InitLiftUp() {
    const forceCode = () => {
        EXPLiftUp.close();
    };

    useEffect(() => {
        const registerLiftUp = () => {
            console.log('%c>> registerLiftUp', 'background: #f0f; color: #fff');
            EXPLiftUp.register(globalLiftUpRef);
        };

        registerLiftUp();
        window.addEventListener('onchangepage', forceCode);

        return () => window.removeEventListener('onchangepage', forceCode);
    });
    return <EXPLiftUp.Init ref={(ref) => (globalLiftUpRef = ref)} />;
}

function App() {
    useEffect(() => {
        const consent = getCookie('localConsent', { domain: 'pharmcare.co' });
        const initScript = () => {
            if (consent === true) {
                // hotjar
                if (process.env.NODE_ENV !== 'test') hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJ_NUMBER);
                //
                const googletagmanager = document.createElement('script');
                googletagmanager.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG_KEY}`;
                googletagmanager.async = true;

                document.body.appendChild(googletagmanager);
                //
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.innerHTML = `
                window.dataLayer = window.dataLayer || []

                function gtag() {
                    dataLayer.push(arguments)
                }
                gtag('js', new Date())
    
                gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG_KEY}')`;

                document.body.appendChild(s);
                //
                const sg = document.createElement('script');
                sg.type = 'text/javascript';
                sg.async = true;
                sg.innerHTML = `
                ;(function (w, d, s, l, i) {
                    w[l] = w[l] || []
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    })
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : ''
                    j.async = true
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
                    f.parentNode.insertBefore(j, f)
                })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY}')`;

                document.body.appendChild(sg);
                //
                const sf = document.createElement('script');
                sf.type = 'text/javascript';
                sf.async = true;
                sf.innerHTML = `
                !(function (f, b, e, v, n, t, s) {
                    if (f.fbq) return
                    n = f.fbq = function () {
                        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    }
                    if (!f._fbq) f._fbq = n
                    n.push = n
                    n.loaded = !0
                    n.version = '2.0'
                    n.queue = []
                    t = b.createElement(e)
                    t.async = !0
                    t.src = v
                    s = b.getElementsByTagName(e)[0]
                    s.parentNode.insertBefore(t, s)
                })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
                
                fbq('init', '${process.env.REACT_APP_FB_EVENTS_KEY}')
                fbq('track', 'PageView')
                `;

                document.body.appendChild(sf);
            }
        };
        initScript();
    }, [window.fbq]);

    // useEffect(() => {
    //     if (firebaseApp) firebaseApp.analytics()
    // }, [firebaseApp])

    return (
        <AuthProvider>
            <PageProvider>
                <ThemeWithPartnerProvider>
                    <Global styles={GlobalStyle} />
                    <DeveloperTools />
                    <RoutesManagement />
                    <InitPopup />
                    <InitLiftUp />
                </ThemeWithPartnerProvider>
            </PageProvider>
        </AuthProvider>
    );
}

export default App;
