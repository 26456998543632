import React, { useEffect, useState } from 'react';
import Footer from 'components/elements/footer';
import { HomeArticle, HomeCertification, HomeMethod, HomeRegister, HomeService, HomeSlide } from 'modules/page/home';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import EnabledDeveloperTools from 'components/common/deverloper-tools/enabled-developer-tools';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';
import { MenuBox, Navigation } from 'components/common/navigation';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import usePageContext from 'hooks/usePageContext';

function Home() {
    const { t, i18n } = useTranslation(['home', 'navigation']);
    const { auth } = useAuth();
    const [searchParams] = useSearchParams();
    const { voucher } = useParams();
    const location = useLocation();
    const theme = useThemeWithPartner();
    const { setPageLoaded } = usePageContext();

    //
    const [language, setLanguage] = useState<string>(i18n.language);

    useEffect(() => {
        const checkSwitchLanguage = () => {
            if (i18n.language !== language) {
                setPageLoaded(false);
                window.location.reload();
            }

            setLanguage(i18n.language);
        };

        checkSwitchLanguage();
    }, [i18n.language]);

    useEffect(() => {
        const init = () => {
            const token = auth.accessToken;
            const pathname = location.pathname;
            const source = searchParams.get('source');

            if (pathname.includes('/home/tlp') && voucher) {
                window.location.assign('line://app/' + process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=' + voucher);
            } else if (pathname.includes('/home/tlm') && voucher) {
                window.location.assign('line://app/' + process.env.REACT_APP_LIFF_APPID_CLAIM_TLM + '?voucher=' + voucher);
            } else if (pathname.includes('/home') && voucher) {
                window.location.assign('line://app/' + process.env.REACT_APP_LIFF_APPID_CLAIM_TLP + '?voucher=' + voucher);
            }
            /** 
            else if (pathname === '/' && (isMobile || isAndroid || isIOS) && !token && source !== 'liff') {
                window.location.assign('line://app/' + process.env.REACT_APP_LIFF_APPID_HOME)
            }
            **/
        };

        init();
    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <meta name='robots' content='index,follow' />
                <link rel='canonical' href='https://app.pharmcare.co/home' />
                <link rel='alternate' href='https://app.pharmcare.co/home' hrefLang='th-TH' />
            </MetaTags>

            <div className='home'>
                <EnabledDeveloperTools />
                <HomeSlide />
                <HomeService />
                <HomeArticle />
                <HomeMethod />
                <HomeCertification />
                <HomeRegister />
            </div>
            <Footer />
            {theme.name !== 'betterperiod' && (
                <WorkplazeThemeHideElement>
                    <Navigation>
                        <MenuBox
                            to='/home'
                            tag='/home'
                            title={t('navigation:home')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='home' />}
                        />
                        {theme.name != 'default_oceanopd' && (
                            <MenuBox
                                to='/phr/existing'
                                tag='/phr'
                                title={t('navigation:health_record')}
                                icon={<IconConfigWithTheme pageName='navigation' iconName='phr' />}
                            />
                        )}
                        <MenuBox
                            to='/service'
                            tag='/service'
                            title={t('navigation:acitivites')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='service' />}
                        />
                    </Navigation>
                </WorkplazeThemeHideElement>
            )}
        </React.Fragment>
    );
}

export default Home;
