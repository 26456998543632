import React, { Component } from 'react'
import LiftUpComponent from './lift-up-component'

export class LiftUpWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lifts: [] // includes multiple models
        }

        this.totalIndex = 0
    }

    // control from utils
    open = ({ ...args }) => {
        const lift = { ...args }
        let { lifts } = this.state

        // isVisible controls the visibility of bottomsheet
        this.totalIndex++

        lift.id = `exp-liftup-${this.totalIndex}`

        // ref controls the lift behaviour, like closing the lift our update the lift
        if (!lift.ref) {
            lift.ref = React.createRef()
        }
        if (!lift.onClose) {
            lift.onClose = () => null
        }

        lifts.push({ ...lift })
        this.setState({ lifts })
    }

    // control from utils
    close = (index = this.state.lifts.length - 1) => {
        let { lifts } = this.state

        setTimeout(() => {
            lifts.splice(index, 1)
            this.setState({ lifts })
        }, 200)

        // *** how to check close = false or onClose
        // in order to retain close effect
        // if (lifts[index]) {
        //     lifts[index].onClose()
        //     this.setState({ lifts })
        // }
    }

    // control from utils
    closeAll = () => {
        let { lifts } = this.state

        // clear all lift
        if (lifts.length > 0) lifts.splice(0, lifts.length)

        this.setState({ lifts })
    }

    // control from utils
    updateProps = ({ ...props }, index = this.state.lifts.length - 1) => {
        const { lifts } = this.state
        lifts[index] = { ...lifts[index], ...props }
        this.setState({ lifts })
    }

    render() {
        const { lifts } = this.state
        return (
            <>
                {lifts.map((lift, index) => (
                    <LiftUpComponent key={lift.id + '' + index} close={() => this.close(index)} {...lift} />
                ))}
            </>
        )
    }
}
