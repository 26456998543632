import { benixTheme } from './benix-theme';
import { betterperiodTheme } from './betterperiod-theme';
import { centralTheme } from './central-theme';
import { defaultThemeCSS } from './default-theme';
import { fastcareTheme } from './fastcare-theme';
import { livewellTheme } from './livewell-theme';
import { pandfTheme } from './pandf-theme';
import { rabbitTheme } from './rabbit-theme';
import { workplazeTheme } from './workplaze-theme';
import { yaphromTheme } from './yaphrom-theme';

export const partnerTheme = {
    default: defaultThemeCSS(),
    // default: workplazeTheme,
    no_config: defaultThemeCSS('no_config'),
    default_pharmcareads: defaultThemeCSS('default_pharmcareads'),
    default_humatrix: defaultThemeCSS('default_humatrix'),
    default_oceanopd: defaultThemeCSS('default_oceanopd'),
    default_sunday: defaultThemeCSS('default_sunday'),
    partner_pharmacy: defaultThemeCSS('partner_pharmacy'),
    doctorhub: defaultThemeCSS('doctorhub'),
    morningmind: defaultThemeCSS('morningmind'),
    ptg: defaultThemeCSS('ptg'),
    cpa: defaultThemeCSS('cpa'),
    central: centralTheme,
    central20: centralTheme,
    central30: centralTheme,
    benix: benixTheme,
    betterperiod: betterperiodTheme,
    fastcare: fastcareTheme,
    rabbit: rabbitTheme,
    workplaze: workplazeTheme,
    yaphrom: yaphromTheme,
    pandf: pandfTheme,
    humatrix: defaultThemeCSS('humatrix'),
    livewell: livewellTheme,
    // humatrix: humatrixTheme
};
