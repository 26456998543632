import styled from '@emotion/styled';
import { Carousel } from 'components/common/Carousel';
import { breakpoint } from 'utils';

export const HomeSlideWrapper = styled(Carousel)`
    label: home-slide;
    height: 500px;
    .carousel-inner {
        height: 100%;
        .carousel-item {
            height: 100%;

            .carousel-element {
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap-reverse;
                width: 90%;
                height: 100%;
                max-width: 1600px;
                z-index: 1;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 30px;
                .carousel-content {
                    text-align: center;
                    .btn-blue {
                        color: var(--btn-blue-color);
                        background-color: var(--btn-blue-background-color);
                        &:hover {
                            color: var(--btn-blue-hover-color);
                            background-color: var(--btn-blue-hover-background-color);
                            ${breakpoint('XL')} {
                                background-color: var(--btn-blue-background-color);
                            }
                        }
                    }
                    .btn-green {
                        color: var(--white);
                        background-color: #44acaa;
                    }
                    strong {
                        font-weight: 500;
                        color: var(--text-normal-1);
                        font-size: 1.8rem;
                    }
                    ${breakpoint('XL')} {
                        text-align: center;
                    }
                }
                .carousel-img {
                    width: 500px;
                }
            }
            .carousel-background {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                img {
                    object-fit: cover;
                }
            }
        }
    }
`;

export const HomeServiceBox = styled('div')<{ backgroundColor: string }>`
    label: home-service-box;

    position: relative;
    width: 96%;
    height: 100%;
    color: var(--white);
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0 0px 10px 0 rgba(255, 140, 90, 0.3);
    margin: auto;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'var(--white)')};
    cursor: pointer;

    .home-service-box-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .home-service-box-title {
        font-size: 1.1rem;
    }
    .home-service-box-detail {
        position: relative;
        font-size: 90%;
        // height: 50px;
        padding-right: 0.125rem;
        ${breakpoint('XS')} {
            font-size: 80%;
        }
    }
    .home-service-box-goto {
        position: absolute;
        bottom: 16px;
        font-size: 90%;
        color: var(--white);
        margin-top: auto;
        ${breakpoint('340px')} {
            position: relative;
            bottom: 0;
        }
    }
    .home-service-box-img {
        position: relative;
        // width: 100%;
        min-width: 146px;
        min-height: 147px;
        height: 125px;
        margin-top: auto;
        margin-left: auto;
        img {
            object-position: right bottom;
        }
        ${breakpoint('XS')} {
            min-width: 105px;
            min-height: 105px;
            height: 80px;
            margin-top: auto;
        }
    }
    .home-service-box-text-subDetail {
        font-size: 80%;
        max-height: 80px;
        margin-top: 0.5rem;
        z-index: 1;
        img {
            object-position: left;
        }
        ${breakpoint('XS')} {
            position: absolute;
            font-size: 68%;
            top: 120%;
        }
        ${breakpoint('340px')} {
            top: calc(100% + 1rem);
        }
    }

    .home-service-box-content {
        position: relative;
        display: flex;
        height: 100%;
        ${breakpoint('XS')} {
            flex-wrap: wrap;
        }
    }
`;

export const HomeServiceWrapper = styled.div`
    label: home-service;

    padding: 24px;
    ${breakpoint('XS')} {
        padding: 24px 0;
    }
    > .home-service-title {
        color: var(--text-normal-1);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .slick-list,
    .slick-slider,
    .slick-track {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .slick-track .slick-slide {
        height: inherit;

        & > div {
            height: 100%;
        }
    }
    .home-service-list {
        width: 100%;
        max-width: 1880px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2.5rem;

        .slick-dots {
            bottom: -35px;
            .slick-active button::before {
                color: var(--primary-color);
            }
            li button:before {
                font-size: 18px;
            }
        }
        ${breakpoint('LG')} {
            .list-scroll {
                flex-wrap: nowrap;
                width: max-content;
            }
        }
    }
`;

export const HomeArticleWrapper = styled.div`
    label: home-article;
    padding-top: 1.5rem;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
    background-color: var(--white);
    > .home-article-title {
        color: var(--text-normal-1);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }
    .home-article-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const HomeRegisterWrapper = styled.div`
    label: home-register;
    position: relative;
    display: flex;
    align-items: center;
    // flex-wrap: wrap-reverse;
    width: 96%;
    margin: 30px auto;
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 30px;
    background-color: var(--text-normal-7);
    overflow: hidden;
    .home-register-left {
        width: 50%;
        margin-top: auto;
        padding-top: 60px;
        padding-right: 60px;
        z-index: 2;
        .home-register-left-img {
            width: 100%;
            max-width: 500px;
            height: 100%;
            max-height: 300px;
            margin-left: auto;
            margin-right: auto;
            img {
                object-position: bottom;
            }
        }
    }
    .home-register-bg-circle {
        position: absolute;
        margin-top: -180px;
        margin-bottom: -150px;
        margin-left: -150px;
        width: calc(50% + 100px);
        height: calc(100% + 360px);
        border-radius: 50%;
        background-color: #8abaff50;
    }
    .home-register-right {
        width: 53%;
        max-width: 500px;
        padding-left: 3%;
        padding-bottom: 24px;
        .home-register-title {
            font-size: 1.3rem;
            font-weight: 500;
            color: var(--text-normal-1);
        }
    }
    ${breakpoint('XS')} {
        flex-wrap: wrap-reverse;
        .home-register-left,
        .home-register-right {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            .home-register-link {
                text-align: center;
            }
        }
        .home-register-bg-circle {
            display: none;
        }
    }
`;

export const HomeCertificationWrapper = styled(Carousel)`
    label: home-certification;
    background-color: #5aacad;
    border-bottom-right-radius: 50px;
    color: var(--white);
    text-align: center;

    .home-certification-avatar {
        position: relative;
        max-width: fit-content;
        width: 120px;
        height: 120px;
        margin: 16px auto;
        .avatar-user {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            img {
                object-fit: cover;
            }
        }
        .certification-star {
            position: absolute;
            top: 0;
            left: 70%;
            width: 160px;
        }
    }

    .home-certification-box {
        padding: 50px;
        ${breakpoint('MD')} {
            padding: 50px 16px;
        }
        .home-certification-box-title {
            font-weight: 500;
            font-size: 1.3rem;
        }
        .home-certification-box-letter {
            font-family: 'Palanquin Dark';
            font-size: 8rem;
            line-height: 1rem;
            transform: rotate(180deg);
            padding-left: 10px;
            color: var(--lightseagreen-1);
        }
        p {
            max-width: 500px;
            text-align: left;
            margin-top: 2rem;
        }
    }
`;
