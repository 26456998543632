import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export type TServiceWithTheme = {
    serviceName: string;
    redirectUrl: string;
    openExternalBrowser?: boolean;
    withParams?: boolean;
    withPharmacyCode?: boolean;
};
export type TServiceData = {
    serviceId: string;
    titleIcon: {
        type: 'icon' | 'image';
        pageName?: string;
        iconName?: string;
        imageOptions?: React.ImgHTMLAttributes<HTMLImageElement>;
    };
    title: string;
    detail: React.ReactNode;
    subDetail: React.ReactNode;
    footer: string;
    imgUrl: string;
    backgroundColor: string;
};

export const SERVICE_REDIRECT_URL_WITH_THEME: { [themeName: string]: TServiceWithTheme[] } = {
    rabbit: [
        {
            serviceName: 'telemed',
            redirectUrl: '/telemed/claim',
        },
    ],
    betterperiod: [{ serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=TLPbetter' }],
    benix: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=benix' },
        { serviceName: 'tlm', redirectUrl: '/select-tlm' },
    ],
    workplaze: [
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=humatrix01' },
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=humatrix01' },
        {
            serviceName: 'nearby',
            openExternalBrowser: true,
            redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL&companyCode=13&voucher=humatrix01',
        },
        { serviceName: 'pt', redirectUrl: '/pt/claim?voucher=humatrix01' },
        { serviceName: 'nt', redirectUrl: '/nt/claim?voucher=humatrix01' },
    ],
    humatrix: [
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=humatrix' },
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=humatrix' },
        {
            serviceName: 'nearby',
            openExternalBrowser: true,
            redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL&companyCode=13&voucher=humatrix',
        },
        { serviceName: 'pt', redirectUrl: '/pt/claim?voucher=humatrix' },
        { serviceName: 'nt', redirectUrl: '/nt/claim?voucher=humatrix' },
    ],
    central: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=tops01' },
        { serviceName: 'tlm', redirectUrl: '/telemed/claim?voucher=tops01' },
        { serviceName: 'mth', withParams: true, redirectUrl: '/mth/claim?voucher=tops01' },
        {
            serviceName: 'nearby',
            openExternalBrowser: true,
            redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL&companyCode=94',
        },
    ],
    central20: [
        { serviceName: 'tlm', withParams: true, redirectUrl: '/tlm/claim' },
        { serviceName: 'fulfillment', withParams: true, redirectUrl: '/fulfillment/claim' },
        // { serviceName: 'tlmNcd', withParams: true, redirectUrl: '/service/telemed-ncd' },
    ],
    central30: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim' },
        { serviceName: 'tlm', withParams: true, redirectUrl: '/tlm/claim' },
    ],
    ptg: [
        { serviceName: 'tlm', redirectUrl: '/tlm/claim' },
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=ptg01' },
    ],
    doctorhub: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=doctorhub01' },
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=doctorhub01' },
    ],
    yaphrom: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=yaphrom01' },
        { serviceName: 'tlm', redirectUrl: '/telemed/claim?voucher=yaphrom01' },
    ],
    cpa: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim' },
        { serviceName: 'telepharmacyCPA', redirectUrl: '/telepharmacy/claim?voucher=cpac201' },
        // { serviceName: 'tlm', redirectUrl: '/tlm/claim' },
    ],
    fastcare: [
        { serviceName: 'telepharmacy', redirectUrl: '/service/telepharmacy-consult?voucher=fastcare01' },
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=fastcare01' },
        {
            serviceName: 'nearby',
            openExternalBrowser: true,
            redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL&companyCode=18&voucher=fastcare-storenearby',
        },
    ],
    default_humatrix: [
        { serviceName: 'tlm', redirectUrl: '/service/telemed/C13?voucher=telemed&billing=human01' },
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=TLP&billing=human01' },
        { serviceName: 'pt', redirectUrl: '/pt/claim?voucher=telemed&billing=human01' },
        { serviceName: 'nt', redirectUrl: '/nt/claim?voucher=telemed&billing=human01' },
    ],
    default_oceanopd: [
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=oceanopd01' },
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=oceanopd01' },
    ],
    default_sunday: [
        //  {  redirectUrl:  window.open('jolly://TELEMEDICINE_MAIN')},serviceName:'tlm',
        { serviceName: 'tlm', redirectUrl: '/tlm/claim?voucher=sunday01' },
    ],
    partner_pharmacy: [{ serviceName: 'telepharmacy', withPharmacyCode: true, redirectUrl: '/telepharmacy/claim?voucher=TLP' }],
    default: [
        { serviceName: 'telepharmacy', withParams: true, redirectUrl: '/service/telepharmacy' },
        { serviceName: 'tlm', withParams: true, redirectUrl: '/service/telemed' },
        // { serviceName: 'tlmNcd', withParams: true, redirectUrl: '/service/telemed-ncd' },
        { serviceName: 'mth', withParams: true, redirectUrl: '/service/mentalhealth' },
        { serviceName: 'fulfillment', redirectUrl: '/fulfillment/claim' },
        { serviceName: 'nearby', openExternalBrowser: true, redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL' },
        { serviceName: 'fda', openExternalBrowser: true, redirectUrl: '/fda' },
        { serviceName: 'pt', withParams: true, redirectUrl: '/service/physiotherapy' },
        { serviceName: 'nt', withParams: true, redirectUrl: '/service/nutritionist' },
        // { serviceName: 'telemed', redirectUrl: '/telemed/claim' },
        // { serviceName: 'telepharmacyLTC', withParams: true, redirectUrl: '/telepharmacy-ltc/claim' },
    ],
    no_config: [
        { serviceName: 'tlm', redirectUrl: '/telemed/claim?voucher=telemed' },
        { serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=TLP' },
    ],
    pandf: [{ serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=pandf01' }],
    morningmind: [{ serviceName: 'mth', redirectUrl: '/mth/claim?voucher=morningmind01' }],
    default_pharmcare: [
        { serviceName: 'telepharmacyLTC', withParams: true, redirectUrl: '/telepharmacy-ltc/claim' },
        { serviceName: 'telepharmacy', withParams: true, redirectUrl: '/service/telepharmacy' },
        { serviceName: 'tlm', withParams: true, redirectUrl: '/service/telemed' },
        { serviceName: 'nearby', openExternalBrowser: true, redirectUrl: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL' },
        // { serviceName: 'mth', withParams: true, redirectUrl: '/service/mentalhealth' },
        { serviceName: 'pt', withParams: true, redirectUrl: '/service/physiotherapy' },
        { serviceName: 'nt', withParams: true, redirectUrl: '/service/nutritionist' },
        // atk: {  redirectUrl: '/service/atk' ),}
    ],
    livewell: [{ serviceName: 'telepharmacy', redirectUrl: '/telepharmacy/claim?voucher=livewell01&show=true' }],
};

const getServiceWithPathname = (pathname: string, params?: string) => {
    let currentParams = params ?? '';

    if (pathname.startsWith('/home-alive')) {
        if (!currentParams) {
            currentParams = '?voucher=aia01&show=true';
        }
        return [
            {
                serviceName: 'telepharmacy',
                redirectUrl: `/telepharmacy/claim${currentParams}`,
            },
            {
                serviceName: 'pt',
                redirectUrl: `/pt/claim${currentParams}`,
            },
        ] as TServiceWithTheme[];
    }
    if (pathname.startsWith('/home-ohc')) {
        if (!currentParams) {
            currentParams = '?voucher=ohc01&show=true';
        }

        return [
            {
                serviceName: 'telepharmacy',
                redirectUrl: `/telepharmacy/claim${currentParams}`,
            },
            {
                serviceName: 'tlm',
                redirectUrl: `/tlm/claim${currentParams}`,
            },
        ] as TServiceWithTheme[];
    }

    return [
        {
            serviceName: 'telepharmacy',
            redirectUrl: `/telepharmacy/claim${currentParams}`,
        },
        {
            serviceName: 'tlm',
            redirectUrl: `/tlm/claim${currentParams}`,
        },
        {
            serviceName: 'pt',
            redirectUrl: `/pt/claim${currentParams}`,
        },
    ] as TServiceWithTheme[];
};

export function useServiceData(aliveData = false) {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();
    const location = useLocation();
    //
    const [serviceCardDetail, setServiceCardDetail] = useState<(TServiceData & TServiceWithTheme)[]>([]);

    const serviceCardData: { [serviceName: string]: TServiceData } = {
        mth: {
            serviceId: 'mthCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'mth' },
            title: 'ปรึกษาจิตแพทย์/นักจิตวิทยาคลินิก',
            detail: 'มีความเครียด ไม่สบายใจ กระทบชีวิตประจำวัน ต้องการให้แพทย์พิจารณาจ่ายยา',
            subDetail: '',
            footer: 'ปรึกษาจิตแพทย์/นักจิต',
            imgUrl: '/images/home/home-service-mth.png',
            backgroundColor: '#44acaa',
        },
        tlm: {
            serviceId: 'tlmCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'tlm' },
            title: t('home:telemedicine'),
            detail: t('home:book_a_doctor_s_consultation'),
            subDetail: '',
            footer: t('home:book_now'),
            imgUrl: '/images/home/home-service-telemed.png',
            backgroundColor: '#3a94ff',
        },
        tlmNcd: {
            serviceId: 'tlmNCDCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'tlm' },
            title: 'ปรึกษาแพทย์โรคเรื้อรังตอนนี้',
            detail: t('home:book_a_doctor_s_consultation'),
            subDetail: '',
            footer: 'ค่าบริการ : 250 บาท',
            imgUrl: '/images/home/home-service-telemed.png',
            backgroundColor: '#b57ba6',
        },
        pt: {
            serviceId: 'ptCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'pt' },
            title: t('home:physiotherapy'),
            detail: 'ปวดเมื่อยกล้ามเนื้อ ต้องการปรึกษานักกายภาพบำบัด',
            subDetail: '',
            footer: t('home:physiotherapy'),
            imgUrl: '/images/home/home-service-pt.png',
            backgroundColor: '#4f9ecb',
        },
        nt: {
            serviceId: 'ntCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'nt' },
            title: t('home:nutritionist'),
            detail: 'ตอบข้อสงสัยเกี่ยวกับอาหารการกิน อาหารลดน้ำหนัก อาหารเบาหวาน หรือโรคอื่นๆ ที่ต้องควบคุมอาหาร',
            subDetail: '',
            footer: t('home:nutritionist'),
            imgUrl: '/images/home/home-service-nt.png',
            backgroundColor: '#3ba274',
        },
        telemed: {
            serviceId: 'telemedCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'telemed' },
            title: t('home:telemedicine'),
            detail: t('home:book_a_doctor_s_consultation'),
            subDetail: '',
            footer: t('home:book_now'),
            imgUrl: '/images/home/home-service-rabbit.png',
            backgroundColor: '#3a94ff',
        },
        telepharmacy: {
            serviceId: 'telepharmacyCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'telepharmacy' },
            title: t('home:telepharmacy'),
            detail:
                theme.name !== 'default'
                    ? t('home:get_instant_consultations_tlp')
                    : t('home:get_instant_consultations') + t('home:like_having_a_family_pharmacist'),
            subDetail: (
                <>
                    {t('home:consultation_fees_may_be_applicable')}
                    <br />
                    {t('home:or_use_voucher_code')}
                </>
            ),
            footer: t('home:consult_now'),
            imgUrl: '/images/home/home-service-tlp.png',
            backgroundColor: 'var(--orange-3)',
        },
        telepharmacyCPA: {
            serviceId: 'telepharmacyCPACard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'telepharmacyLTC' },
            title: 'ร้านยารักษ์ไต',
            detail: 'ร้านยารักษ์ไต ค่าบริการเภสัชได้รับการสนันสนุนโดยสภช.',
            subDetail: '',
            footer: t('home:consult_now'),
            imgUrl: '/images/home/home-service-tlp.png',
            backgroundColor: '#44acaa',
        },
        telepharmacyLTC: {
            serviceId: 'telepharmacyLTCCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'telepharmacyLTC' },
            title: 'เติมยาโรคเรื้อรัง',
            detail: 'อัพโหลดข้อมูล และรอรับยาที่บ้าน',
            subDetail: '',
            footer: 'เติมยากับเภสัชกร',
            imgUrl: '/images/home/home-service-tlp-ltc.webp',
            backgroundColor: 'var(--second-color)',
        },
        fulfillment: {
            serviceId: 'fulfillmentCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'telepharmacyLTC' },
            title: 'สั่งยาตามใบสั่งแพทย์',
            detail: 'อัพโหลดใบสั่งยา และไปรับยาที่ร้านขายยา',
            subDetail: '',
            footer: 'สั่งยาตามใบสั่ง',
            imgUrl: '/images/home/home-service-ful.png',
            backgroundColor: '#44acaa',
        },
        nearby: {
            serviceId: 'nearbyCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'nearby' },
            title: t('home:pharmacy_directory'),
            detail:
                theme.name !== 'default'
                    ? t('home:find_network_pharmacies_near_you')
                    : t('home:find_quality_pharmcare_network_pharmacies_near_you'),
            subDetail: '',
            footer: t('home:find_a_pharmacy'),
            imgUrl: '/images/home/home-serivcr-nearby.png',
            backgroundColor: 'var(--blue-3)',
        },
        fda: {
            serviceId: 'fdaCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'fda' },
            title: t('home:pharmacy_directory_FDA'),
            detail: t('home:all_FDA_registered_pharmacies_in_bangkok'),
            subDetail: <img src='/images/home/home-service-fda.png' alt='home-service-fda' style={{ width: '3.1rem', height: '3.1rem' }} />,
            footer: t('home:find_a_pharmacy'),
            imgUrl: '/images/home/home-serivcr-nearby.png',
            backgroundColor: 'rgb(0,82,156)',
        },
        atk: {
            serviceId: 'atkCard',
            titleIcon: (
                <img
                    src='/images/home/home-service-atk.svg'
                    className='d-inline-block'
                    style={{ width: '1.1rem', height: '1.1rem' }}
                    alt=''
                />
            ),
            title: t('home:purchase_covid_19_antigen_test_kit'),
            detail: (
                <>
                    {t('home:worried_you_may_be_at_risk')}
                    <br />
                    {t('home:consult_a_pharmacist_to_get_your_antigen_test_kit')}
                </>
            ),
            subDetail: '',
            footer: '',
            imgUrl: '/images/home/home-service-covid.png',
            backgroundColor: '#b57ba6',
        },
    };

    const serviceAliveCardData: { [serviceName: string]: TServiceData } = {
        tlm: {
            serviceId: 'tlmCard',
            titleIcon: { type: 'icon', pageName: 'home_service', iconName: 'tlm' },
            title: t('home:telemedicine'),
            detail: t('home:book_a_doctor_s_consultation'),
            subDetail: '',
            footer: t('home:book_now'),
            imgUrl: '/images/home/home-service-telemed.png',
            backgroundColor: '#3a94ff',
        },
        pt: {
            serviceId: 'ptCard',
            titleIcon: { type: 'icon', pageName: 'home_service_alive', iconName: 'pt' },
            // titleIcon: <i className='fas fa-dumbbell'></i>,
            title: 'กายภาพบำบัด',
            detail: 'ปวดเมื่อยกล้ามเนื้อ ต้องการปรึกษานักกายภาพบำบัด',
            subDetail: '',
            footer: 'ปรึกษานักกายภาพบำบัด',
            imgUrl: '/images/home/home-service-pt.png',
            backgroundColor: '#4f9ecb',
        },

        telepharmacy: {
            serviceId: 'telepharmacyCard',
            titleIcon: { type: 'icon', pageName: 'home_service_alive', iconName: 'telepharmacy' },
            // titleIcon: <i className='fas fa-video'></i>,
            title: t('home:telepharmacy'),
            detail:
                theme.name !== 'default'
                    ? t('home:get_instant_consultations_tlp')
                    : t('home:get_instant_consultations') + t('home:like_having_a_family_pharmacist'),
            subDetail: (
                <>
                    {t('home:consultation_fees_may_be_applicable')}
                    <br />
                    {t('home:or_use_voucher_code')}
                </>
            ),
            footer: t('home:consult_now'),
            imgUrl: '/images/home/home-service-tlp.png',
            backgroundColor: 'var(--orange-3)',
        },
    };

    useEffect(() => {
        const getCardData = () => {
            let temp: (TServiceData & TServiceWithTheme)[] = [];

            if (aliveData) {
                const service = getServiceWithPathname(location.pathname, location.search);
                service.forEach((item) => {
                    temp.push({
                        ...item,
                        ...serviceAliveCardData[item.serviceName],
                    });
                });
            } else {
                const service = SERVICE_REDIRECT_URL_WITH_THEME[theme.name];
                service.forEach((item) => {
                    temp.push({
                        ...item,
                        ...serviceCardData[item.serviceName],
                    });
                });
            }

            setServiceCardDetail([...temp]);
        };

        getCardData();
    }, [theme.name]);

    return serviceCardDetail;
}
