import React from 'react'
import axios from 'axios'
import { clearUserLocalStorage, paramsjs, cryptojs } from 'utils'
import {
    browserName,
    browserVersion,
    engineName,
    engineVersion,
    isIOS,
    isMobile,
    isTablet,
    mobileModel,
    mobileVendor,
    osName,
    osVersion
} from 'react-device-detect'
import EXPPopupJs from 'components/common/exp-popupjs'
import { ISINAPPWEBVIEWBROWSER } from 'constants/default-config'
import i18n from '../i18n'
import { getCookie } from 'cookies-next'

const BASE_URL = process.env.REACT_APP_API_URL
const popup_try_again_1 = {
    th: 'แจ้งเตือน',
    en: 'Warning'
}
const popup_try_again_2 = {
    th: 'กรุณากดตกลงเพื่อเชื่อมต่ออีกครั้ง',
    en: 'Please select Try Again to refresh'
}
const popup_try_again_3 = {
    th: 'ตกลง',
    en: 'Try Again'
}

const getDevice = () => {
    if (isMobile) return 'Mobile'

    if (isTablet) return 'Tablet'

    return 'Desktop'
}

const userDevice = JSON.stringify({
    device: getDevice(),
    mobileVendor: mobileVendor,
    mobileModel: mobileModel,
    osName: osName,
    osVersion: osVersion,
    browserName: browserName,
    browserVersion: browserVersion,
    engineName: engineName,
    engineVersion: engineVersion
})

function handlePharmcareError(json, reject, requestPath) {
    let redirectPath = '/user/line?to='

    if (json?.userProfile?.userType === 'EMAIL') {
        redirectPath = '/user/login?to='
    }
    if (json?.userProfile?.userType === 'SSO') {
        const userSsoProfile = json?.userProfile?.userSsoProfile
        redirectPath = `/user/sso?sfssoid=${userSsoProfile?.svcId}&accname=${userSsoProfile?.accName}&pcparam=`
    }
    // return
    // if not some in array return
    if ([401, 403, 407, 408, 417, 500].every((s) => json.statusCode !== s)) {
        reject({ ...json, isNoPopup: true })
    }

    // Unauthorized
    if (json.statusCode === 401) {
        console.error(json)
        //
        // reject(json)

        EXPPopupJs.alert({
            type: 'warning',
            icon: 'warning',
            title: 'เกิดข้อผิดพลาด',
            message: 'Unauthorized401 User',
            classNames: { message: 'font-detail2' },
            buttons: [
                {
                    label: 'ตกลง',
                    variant: 'primary',
                    action: () => {
                        clearUserLocalStorage()
                        // window.location.assign(redirectPath + cryptojs.encode(from))
                        window.location.assign('/home')
                    }
                }
            ]
        })

        if (requestPath === '/user/validateToken') reject({ ...json, isNoPopup: false })
    }

    // Forbidden Signup
    if (json.statusCode === 403) {
        console.error(json)
        //
        // reject(json)

        EXPPopupJs.alert({
            type: 'warning',
            icon: 'warning',
            title: 'เกิดข้อผิดพลาด',
            message: json.rejectReason,
            classNames: { message: 'font-detail2' },
            buttons: [
                {
                    label: 'ตกลง',
                    variant: 'primary',
                    action: () => {
                        clearUserLocalStorage()
                        // window.location.assign(redirectPath + cryptojs.encode(from))
                        // window.location.assign('/home')
                    }
                }
            ]
        })
    }

    // Token Expired
    if (json.statusCode === 408) {
        console.error(json)
        //
        let encodeParams = ''
        let from = {
            path: '/',
            params: ''
        }
        const { pcparam } = paramsjs.query(window.location.pathname)
        //
        if (window.location.pathname.startsWith('/user/sso') && pcparam) {
            const decodeParams = cryptojs.decode(pcparam)

            if (!isMobile && decodeParams?.data?.userType === 'SSO') {
                redirectPath = window.location.href
            } else if (
                decodeParams?.data?.partner === '13' &&
                decodeParams?.data?.userType === 'SSO' &&
                isMobile &&
                !(isIOS && osVersion <= '14.3.0') &&
                !ISINAPPWEBVIEWBROWSER.some((e) => e === browserName) &&
                window.location.pathname.startsWith('/user/sso')
            ) {
                let nextPath = window.location.href
                let url = window.encodeURIComponent(nextPath)

                const pharmcarePath = url
                // redirectPath =
                //     'https://workplaze.page.link/?link=https://workplaze.page.link/' +
                //     pharmcarePath +
                //     '&apn=com.hmc.workplaze&isi=1637565364&ibi=com.hmc.workplaze'
                redirectPath =
                    'workplaze://myplaze/marketplace/health?link=' +
                    pharmcarePath +
                    '&apn=com.hmc.workplaze&isi=1637565364&ibi=com.hmc.workplaze'
            }
        } else if (
            json?.userProfile?.userXPartner &&
            json?.userProfile?.userXPartner.partnerTheme === 'workplaze' &&
            isMobile &&
            !ISINAPPWEBVIEWBROWSER.some((e) => e === browserName) &&
            json?.userProfile?.userType === 'SSO'
        ) {
            from = {
                path: '/phr/existing',
                data: {
                    partner: '13',
                    userType: 'SSO',
                    authorization: null,
                    userId: null,
                    autoLogin: '1',
                    companyCode: '',
                    language: 'TH',
                    rich: null
                }
            }

            if (pcparam) {
                from = {
                    ...from,
                    ...cryptojs.decode(pcparam)
                }
            }
            if (this.params?.language) {
                from = {
                    ...from,
                    data: {
                        ...from.data,
                        language: this.params.language?.toLocaleUpperCase()
                    }
                }
            }
        } else if (window.location.pathname === '/line/redirect') {
            const params = paramsjs.query(window.location.search)
            let to = params?.to || ''

            if (params['liff.state']) {
                const decodeUri = window.decodeURIComponent(params['liff.state'])
                const liffState = paramsjs.query(decodeUri)

                to = liffState?.to || ''
            }

            if (cryptojs.isEncode(to)) {
                const decodeParams = cryptojs.decode(to)

                from = {
                    path: decodeParams.path,
                    params: decodeParams.params,
                    data: {
                        ...decodeParams?.data,
                        authorization: null
                    }
                }
            }
        } else if (window.location.pathname === '/partner20/redirect/user-plugin') {
            const decodeLineState = cryptojs.decode(json?.userProfile?.lineState)
            const params = paramsjs.query(window.location.search)
            let paramsLiffState = window.location.search

            if (params['liff.state']) {
                paramsLiffState = window.decodeURIComponent(params['liff.state'])
            }

            from = {
                path: '/tlm/claim',
                params: paramsLiffState,
                data: {
                    ...decodeLineState?.data,
                    partner: '20',
                    autoLogin: 0,
                    authorization: null
                }
            }
        } else if (cryptojs.isEncode(json?.userProfile?.lineState)) {
            const decodeParams = cryptojs.decode(json?.userProfile?.lineState)
            from = {
                path: window.location.pathname,
                params: window.location.search || '',
                data: {
                    ...decodeParams?.data,
                    autoLogin: 0,
                    authorization: null
                }
            }
        }

        clearUserLocalStorage()

        encodeParams = cryptojs.encode(from)
        if (
            process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE === 'true' &&
            ((json?.userProfile?.userXPartner && json?.userProfile?.userXPartner.partnerTheme === 'workplaze') || pcparam)
        ) {
            alert('browserName: ' + browserName)
            alert(redirectPath + encodeParams)
        }
        // redirect
        window.location.assign(redirectPath + encodeParams)
    }

    if (json.statusCode === 407 || json.statusCode === 417 || json.statusCode === 500) {
        console.error(json)
        EXPPopupJs.alert({
            icon: 'warning',
            title: 'เกิดข้อผิดพลาด',
            message: 'เนื่องจากระบบล่ม กรุณาติดต่อ Pharmcare',
            buttons: [
                {
                    label: 'ตกลง',
                    variant: 'danger',
                    action: ({ close }) => {
                        reject({ ...json, isNoPopup: false })
                        close()
                    }
                }
            ]
        })

        if (requestPath === '/user/validateToken') reject({ ...json, isNoPopup: false })
    }
}

export const GET = (path) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                'Content-Type': 'application/json',
                'User-Device': userDevice,
                // Multiple types, weighted with the quality value syntax:
                // Accept-Language: fr-CH, fr;q=0.9, en;q=0.8, de;q=0.7, *;q=0.5
                'Accept-Language': i18n.language.toLowerCase(), // 'en' | 'th'
                'Referer-Path': window.location.pathname
            },
            method: 'get',
            url: `${BASE_URL}${path}`,
            data: ''
        })
            .then((res) => res.data)
            .then((json) => {
                if (json.statusCode >= 301) {
                    handlePharmcareError(json, reject)
                } else {
                    resolve(json)
                }
            })
            .catch((error) => {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: 'เกิดข้อผิดพลาด',
                    message: error,
                    buttons: [
                        {
                            label: 'ตกลง',
                            action: ({ close }) => {
                                reject({ ...json, isNoPopup: false })
                                close()
                            }
                        }
                    ]
                })
            })
    })
}

export const POST = (path, obj, formData = false, options = {}) => {
    const userToken = getCookie('userToken', { domain: process.env.REACT_APP_DOMAIN })
    const bearer = userToken ? 'Bearer ' + userToken : ''

    return new Promise((resolve, reject) => {
        axios({
            headers: {
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
                Authorization: bearer || '',
                'User-Device': userDevice,
                // Multiple types, weighted with the quality value syntax:
                // Accept-Language: fr-CH, fr;q=0.9, en;q=0.8, de;q=0.7, *;q=0.5
                'Accept-Language': i18n?.language?.toLowerCase(), // 'en' | 'th'
                'Referer-Path': window.location.pathname,
                ...options.headers
            },
            method: 'post',
            url: `${BASE_URL}${path}`,
            data: formData ? obj : { languageEn: i18n?.language?.toLowerCase() === 'th' ? 0 : 1, ...obj }
        })
            .then((res) => res.data)
            .then((json) => {
                if (json.statusCode >= 301) {
                    handlePharmcareError(json, reject, path)
                } else {
                    resolve(json)
                }
            })
            .catch((error) => {
                console.error(error)

                EXPPopupJs.alert({
                    type: 'warning',
                    icon: 'warning',
                    title: popup_try_again_1[i18n.language],
                    message: popup_try_again_2[i18n.language],
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: popup_try_again_3[i18n.language],
                            variant: 'danger',
                            action: () => window.location.reload()
                        }
                    ]
                })
            })
    })
}

export const Omise = (obj, omiseBasicAuthenEnc = '') => {
    return new Promise(async (resolve, reject) => {
        axios({
            headers: {
                'Content-Type': 'application/json',
                'Referer-Path': window.location.pathname,
                Authorization: 'Basic ' + cryptojs.decode(cryptojs.decode(cryptojs.decode(omiseBasicAuthenEnc))) + ':'
            },
            method: 'POST',
            url: `https://vault.omise.co/tokens`,
            data: obj
        })
            .then((res) => res.data)
            .then((json) => resolve(json))
            .catch((error) => {
                reject(error?.response?.data)
            })
    })
}
