import { POST } from 'api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoint, numberjs } from 'utils';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';

function HomeStepsReceiveService() {
    const [statistics, setStatistics] = useState({});
    const getStatistics = async () => {
        try {
            let result = await POST('/cms/statistics/pharmacy', {});
            setStatistics(result.data);
        } catch (error) {
            console.error('cms/hero ~ error', error);
        }
    };
    useEffect(() => {
        getStatistics();
    }, []);

    return (
        <HomeStepReceiveServiceWrapper>
            <MethodTopContent statistics={statistics} />
            <MethodBottom />
        </HomeStepReceiveServiceWrapper>
    );
}
function MethodTopContent({ statistics }) {
    return (
        <MethodTopContentStyle>
            <MethodTopLeft statistics={statistics} />
            <MethodTopRight />
        </MethodTopContentStyle>
    );
}
function MethodTopLeft({ statistics }) {
    const { t } = useTranslation();
    const topBox = (img, text, num) => {
        return (
            <div className='box'>
                <div className='-img'>
                    <img src={img} alt='' />
                </div>
                <div>
                    <span>{text}</span>
                    <br />
                    <span className='text-primary fw-500'>{numberjs(num).format('int')}</span>
                </div>
            </div>
        );
    };
    return (
        <MethodTopLeftStyle>
            {topBox('/images/home/pill-jar.png', t('home:number_of_pharmacies_in_our_network'), statistics?.totalStores)}
            {topBox('/images/home/stethoscope.png', t('home:total_number_of_services'), statistics?.totalServices)}
            {topBox('/images/home/pharmacist-icon.png', t('home:experienced_pharmacist_in_our_netork'), statistics?.totalPharmacists)}
        </MethodTopLeftStyle>
    );
}
function MethodTopRight() {
    return (
        <MethodTopRightStyle>
            <div className='doctor'>
                <img src='/images/home/home-method-doctor.png' alt='home-method-doctor' />
            </div>
            <div className='clinic'>
                <img src='/images/home/home-method-clinic.png' alt='home-method-clinic' />
            </div>
        </MethodTopRightStyle>
    );
}
function MethodBottom() {
    const [method_service, setMethod_service] = useState('step1');
    const onChangeStep = (step) => {
        setMethod_service(step);
    };
    const imgService = (img, step) => {
        return <img src={img} alt='' className={method_service === step ? 'd-block' : 'd-none'} />;
    };

    return (
        <MethodBottomStyle>
            <div className='-img'>
                {imgService('/images/home/home-talk-to-pharmacist.png', 'step1')}
                {imgService('/images/home/home-pharmacist-service.png', 'step2')}
                {imgService('/images/home/home-delivery.png', 'step3')}
            </div>
            <MethodBottomStep method_service={method_service} onChangeStep={onChangeStep} />
        </MethodBottomStyle>
    );
}
function MethodBottomStep({ method_service, onChangeStep }) {
    const { t } = useTranslation();
    const topBox = (step, text1, text2, text3) => {
        return (
            <div className={`-box ${method_service === step ? '_active' : 'hidden'}`} onClick={() => onChangeStep(step)}>
                <div className='-title'>
                    <div className='-circle'>{text1}</div>
                    <strong>{text2}</strong>
                </div>
                <p>{text3}</p>
            </div>
        );
    };
    return (
        <MethodBottomStepStyle>
            <div className='-title' data-test-id='title'>
                {t('home:3_simple_steps')}{' '}
                <IconConfigWithTheme pageName='home_method' iconName='steps_to_start' className='text-orange-2' />
            </div>
            <p className='text-black-transparent-90 _web' style={{ marginBottom: '0rem' }}>
                {t('home:our_pharmacists')}
            </p>
            <p className='text-black-transparent-90 _web'>{t('home:get_serviced_immediately')}</p>

            <div className='-list'>
                {topBox('step1', '1', t('home:1_video_consultation'), t('home:start_your_consultation'))}
                {topBox(
                    'step2',
                    '2',
                    t('home:2_pharmacist_will_dispense_medication_according'),
                    t('home:if_your_conditions_require_medication')
                )}
                {topBox('step3', '3', t('home:3_get_your_medication_delivered_straight'), t('home:confirm_your_medication'))}
            </div>
        </MethodBottomStepStyle>
    );
}

const HomeStepReceiveServiceWrapper = styled.div`
    label: home-method;
    border-top-left-radius: 50px;
    background-color: #e2eff0;
    padding: 40px 18px;
`;
const MethodTopContentStyle = styled.div`
    label: home-method-top;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    ${breakpoint('XS')} {
        flex-wrap: nowrap;
    }
`;

const MethodTopLeftStyle = styled.div`
    label: method-top-left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
    color: var(--text-normal-1);
    .box {
        display: flex;
        margin-right: 3rem;
        margin-bottom: 1rem;
        > div {
            display: inline-block;
            vertical-align: middle;
        }
        .-img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            padding: 8px;
            box-shadow: 0 0 8px 0 rgba(0, 81, 80, 0.3);
            background-color: var(--green-3);
            margin-right: 1rem;
        }
        ${breakpoint('XS')} {
            margin-right: 1rem;
        }
    }
`;
const MethodTopRightStyle = styled.div`
    label: method-top-right;
    display: flex;
    margin-top: auto;
    .doctor {
        width: 60px;
        margin-top: auto;
    }
    .clinic {
        width: 380px;
    }
    ${breakpoint('XS')} {
        position: absolute;
        right: 0;
        bottom: 0;
        .clinic {
            display: none;
        }
    }
`;
const MethodBottomStyle = styled.div`
    label: home-method-bottom;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-around;
    border-radius: 40px;
    box-shadow: 0 0 10px #00000020;
    background-color: var(--white);
    padding: 50px;
    padding-bottom: 0;

    .-img {
        width: 280px;
        margin-top: auto;
    }
    ${breakpoint('XS')} {
        padding: 24px;
        padding-bottom: 0;
        border-radius: 24px;
        .-img {
            width: 220px;
        }
    }
`;
const MethodBottomStepStyle = styled.div`
    label: method-bottom-step;
    width: calc(100% - 500px);
    > .-title {
        font-size: 1.3rem;
        color: var(--text-normal-1);
        font-weight: 500;
    }
    .-list .-box {
        display: inline-block;
        width: calc(100% / 3);
        padding-right: 1rem;
        vertical-align: top;
        color: var(--text-normal-4);
        cursor: pointer;
        .-circle {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            color: var(--white);
            text-align: center;
            border-radius: 50%;
            margin-right: 10px;
            background-color: var(--text-normal-4);
        }
        &._active {
            color: var(--text-normal-1);
            .-circle {
                background-color: var(--second-color);
            }
        }
    }
    ${breakpoint('XL')} {
        width: calc(100% - 400px);
        .-list .-box {
            display: block;
            width: 100%;
        }
    }
    ${breakpoint('LG')} {
        width: 100%;
    }
    ${breakpoint('XS')} {
        > .-title {
            margin-bottom: 2rem;
        }
        ._web {
            display: none;
        }
    }
`;
export default HomeStepsReceiveService;
