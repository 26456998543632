import React, { useEffect, useState } from 'react'
import { LiftUpUtils } from './lift-up-utils'

/**
 * @version 1.0.3 update container width and close icon
 * @version 1.0.2 add parameter "maxWidth"
 * @version 1.0.1 create
 *
 * @default isClickOutSideClose = true
 *
 * @param {{
 * show: Boolean,
 * showClose: Boolean,
 * isClickOutSideClose: Boolean,
 * onlyLiftup: Boolean,
 * zIndex: String | Number | 999,
 * liftSize: 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL' | 'XXXL',
 * backgroundColor: String,
 * maxWidth: String,
 * className: String,
 * children: Element,
 * onClose:()=> void
 * }} props
 * @returns
 */
function SlideUp({ show, children, onClose = () => null, ...props }) {
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        if (show) {
            LiftUpUtils.open({ show, onClose, children, ...props })
            setIsShow(true)
        }

        if (!show && isShow) {
            LiftUpUtils.close()
            setIsShow(false)
        }
    }, [show])

    useEffect(() => {
        if (isShow) {
            LiftUpUtils.updateProps({ show, onClose, children, ...props })
        }
    }, [children])

    return <></>
}

export default SlideUp
